<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-tabs v-model="activeTabIndex">
          <b-tab
            title="New Integration"
          >
            <div class="col-12 mt-4">
              <div class="card">
                <div class="card-body">
                  <div v-if="!isLoadingIntegrations" class="list-integrations">
                    <div v-for="integration in integrations" :key="integration.id" class="integration-item"
                      :class="{ selected: selectedIntegration && selectedIntegration.id === integration.id }"
                      @click="handleSelectIntegration(integration)"
                      >
                      <img :src="integration.icon" :alt="integration.title" class="integration-icon">
                      <h3>{{ integration.title }}</h3>
                      <p>{{ integration.description }}</p>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <b-spinner></b-spinner>
                  </div>
                  <div class="row mt-4">
                    <div class="col-6">
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                        v-if="selectedIntegration" 
                        variant="primary"
                        type="button"
                        @click="onCreateIntegration"
                      >
                        <span>Select</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            title="Existing Integration"
          >
            <div class="card">
              <div class="card-body">
                <div v-if="!isLoadingIntegrations && integrationUsers" class="list-integration-users">
                  <div v-if="!integrationUsers.data.length" class="d-flex justify-content-center align-items-center" style="min-height: 100px">
                    No Integrations Available
                  </div>
                  <div v-if="integrationUsers.data.length">
                    <div v-for="integration in integrationUsers.data" :key="integration.id" class="integration-item">
                      <img :src="integration.icon" :alt="integration.title" class="integration-icon">
                      <div class="title-wrapper">
                        <h3>{{ integration.title }}</h3>
                        <span class="pl-4 text-muted font-weight-bold">{{ integration.name }}</span>
                      </div>
                      <div class="table-actions-group">
                        <router-link
                          :to="{
                            name: 'business.settings.integrations.edit',
                            params: { id: integration.id },
                          }"
                          class="btn btn-outline-dark"
                        >
                          <i class="uil uil-edit"></i> Edit
                        </router-link>
                        <b-dropdown
                          variant="outline-dark"
                          right
                        >
                        <template v-slot:button-content>
                          <b-spinner v-if="loadingRemove === integration.id" small />
                          <i v-else class="uil uil-angle-down"></i>
                        </template>
                          <b-dropdown-item href="#"
                            :disabled="loadingRemove === integration.id"
                            @click="onDestroy(integration)">
                            <span class="text-danger">
                              <i class="uil uil-trash"></i> Delete
                            </span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col">
                        <div class="float-left mt-2">
                          Total {{ integrationUsers.meta.total }} integrations
                        </div>
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                              v-model="queries.page"
                              :total-rows="integrationUsers.meta.total"
                              :per-page="integrationUsers.meta.per_page"
                              @change="onPageChanged"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        
      </div>
      
    </div>
    <b-modal v-model="showDeleteModal" title="Delete contact">
      <p v-if="selectedItem">Are you sure you want to delete integration #<b>"{{ selectedItem.id }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingIntegrations: false,
      integrations: [],
      selectedIntegration: null,
      integrationUsers: null,
      processing: null,
      loadingRemove: null,
      selectedItem: null,
      showDeleteModal: false,
      queries: {
        per_page: 15,
        page: 1,
      },
      activeTabIndex: 0,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    useIntegration() {
      return this.user && this.user.business && this.user.business.use_integration
    },
  },

  watch: {
    activeTabIndex(val) {
      if (val === 1) {
        this.getIntegrationUsers()
      }
    }
  },


  mounted() {
    this.getIntegrations()
    if (this.$route.query.tab)
    {
      this.$nextTick(() => {
        this.activeTabIndex = parseInt(this.$route.query.tab)
      })
    }
  },

  methods: {
    onPageChanged(page) {
      this.queries.page = page
      this.getIntegrationUsers()
    },

    getIntegrationUsers() {
      this.isLoadingIntegrations = true

      this.$store
        .dispatch('integration/allUser', this.queries)
        .then((integrations) => {
          this.integrationUsers = integrations
          this.isLoadingIntegrations = false
        })
        .catch(() => {
          this.isLoadingIntegrations = false
        })
    },

    getIntegrations() {
      this.isLoadingIntegrations = true

      this.$store
        .dispatch('integration/all')
        .then((integrations) => {
          this.integrations = integrations
          if (!this.useIntegration)
          {
            this.integrations = this.integrations.filter(item => item.key !== 'samcart')
          }
          if (process.env.NODE_ENV === 'production' && ![4776,3379].includes(this.user.id)) {
            this.integrations = this.integrations.filter(item => item.key !== 'stripe')
          }
          if (process.env.NODE_ENV === 'production' && this.user.business && ![2,1].includes(this.user.business.agency_id)) {
            this.integrations = this.integrations.filter(item => item.key !== 'ses')
          }
          this.isLoadingIntegrations = false
        })
        .catch(() => {
          this.isLoadingIntegrations = false
        })
    },

    handleSelectIntegration(integration) {
      this.selectedIntegration = integration
    },

    onCreateIntegration() {
      this.$router.push({ name: 'business.settings.integrations.create', query: {id: this.selectedIntegration.id} })
    },

    onDestroy(item) {
      this.showDeleteModal = true
      this.selectedItem = item
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedItem.id

      this.$store
        .dispatch('integration/delete', this.selectedItem.id)
        .then((res) => {
          this.$store.dispatch('auth/getProfile')
          this.integrationUsers.data = this.integrationUsers.data.filter(item => item.id !== this.loadingRemove)
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleChangeIntegrationStatus(integrationId) {
      this.processing = integrationId

      this.$store
        .dispatch('integration/toggleStatus', integrationId)
        .then((integration) => {
          this.integrationUsers.data = this.integrationUsers.data.map(i => i.id === integration.id ? integration : i)
          this.processing = null
        })
        .catch(() => {
          this.processing = null
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.list-integrations {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
  .integration-item {
    border: 2px solid #cbcbcb;
    border-radius: 5px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 30px 15px;
    width: 300px;
    cursor: pointer;
    text-align: center;
    h3 {
      font-size: 16px;
    }
    .integration-icon {
      max-height: 80px;
      height: 80px;
      margin: auto;
      display: block;
    }
    &:hover, &.selected {
      border-color: #4284f4;
    }
  }
}

.list-integration-users {
  .integration-item {
    border: 1px solid #cbcbcb;
    box-shadow: 0px 0px 6px #cbcbcb;
    border-radius: 5px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    padding: 10px 20px;
    .title-wrapper {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      h3 {
        font-size: 18px;
      }
    }
    .integration-icon {
      margin-right: 10px;
      flex: 0 0 70px;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: left;
    }
  }
}
</style>